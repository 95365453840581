
  import {prepareGeoApifyReverseSearchUrl} from '@/helper/UrlHelper';
  import axios from 'axios';

  export default {
    name: 'SectionHomepageJobBoxWithTabs',
    components: {
      JobBoxWithTabs: () => import('@/components/JobBoxWithTabs'),
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        filterParams: '',
        whereInput: '',
      };
    },
    async fetch() {
      const filterParams = new URLSearchParams();
      if (this.section.filterParams && this.section.filterParams.search) {
        filterParams.append('search', this.section.filterParams.search);
      }

      if (this.section.filterParams && this.section.filterParams.activities) {
        this.section.filterParams.activities.split(',').forEach((value) => {
          filterParams.append('activity[]', value.trim());
        });
      }

      if (
        this.section.filterParams &&
        this.section.filterParams.latitude &&
        this.section.filterParams.longitude &&
        this.section.filterParams.distance
      ) {
        await this.getGeoLocation(
          this.section.filterParams.latitude,
          this.section.filterParams.longitude
        );
        if (this.whereInput) {
          filterParams.append(
            'distance',
            `${this.section.filterParams.latitude},${this.section.filterParams.longitude},${this.section.filterParams.distance}`
          );
          filterParams.append('whereInput', this.whereInput);
        }
      }

      if (this.section.filterParams && this.section.filterParams.tags) {
        this.section.filterParams.tags.split(',').forEach((value) => {
          filterParams.append('tagsOr[]', value.trim());
        });
      }
      this.filterParams = filterParams.toString();
    },
    methods: {
      async getGeoLocation(lat, lon) {
        await axios
          .get(prepareGeoApifyReverseSearchUrl(lat, lon))
          .then((result) => {
            if (
              result &&
              result.data &&
              result.data.features &&
              result.data.features[0]
            ) {
              this.whereInput =
                result.data.features[0].properties['address_line1'] +
                ',' +
                result.data.features[0].properties['address_line2'];
            }
          })
          .catch({});
      },
    },
  };
